import { useEffect, useState } from 'react';
import Onboarding from '../components/Onboarding';
import { GetWLData } from '../services/wl';
import { redirect } from 'next/dist/server/api-utils';
import { getUrls } from '../services/wl/getUrls';
import SplashScreen from '../components/SplashScreen';
export default function Home(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <SplashScreen wl={props.wl} />;
  }

  return (
    <>
      <Onboarding
        bgImg={
          props.wl?.usar_configuracao_padrao
            ? '/images/onboarding/onboarding-back-01.svg'
            : ''
        }
        mascotImg={props.mascotImg}
        titlePage={props.title}
        descPage={props.description}
        skipPage
        onboarding={1}
        nextUri={'/onboarding/onboarding-2'}
        wl={props.wl}
      />
    </>
  );
}

export async function getServerSideProps(context) {
  const result = await GetWLData({
    keyName: `
    Onboarding{
      header_color
      titulo_bem_vindo
      titulo_onboarding_1
      descricao_onboarding_1
      bg_img_onboarding_1{
        data{
          attributes{
            url
          }
        }
      }
    }
    
    Calculadora{
      texto_captura_fornecedor
      habilitar_captura_fornecedor
    }
    `,
  });

  if (!result.status) {
    return {
      redirect: {
        permanent: false,
        destination: '/error?tag_error=white_label',
      },
      props: {},
    };
  }

  const mascotImg = getUrls(
    `${result.data.Onboarding.bg_img_onboarding_1.data.attributes.url}`
  );

  return {
    props: {
      mascotImg: mascotImg,
      wl: result.data,
      title: result.data.Onboarding.titulo_onboarding_1,
      description: result.data.Onboarding.descricao_onboarding_1,
    },
  };
}
